<template>
    <div class="screen">
        <router-link v-if="continueHref" :to="continueHref" class="continue">Continue to app</router-link>
        <div v-if="error" class="error">{{ String(error) }}</div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { doOauth } from '.';

export default defineComponent({
    data() {
        return {
            continueHref: null as string | null,
            error: null as unknown,
        };
    },

    async mounted() {
        try {
            await doOauth();
            this.continueHref = '/';
        } catch (error) {
            this.error = error;
        }
    },
});
</script>

<style scoped>
.screen {
    align-items: center;
    display: flex;
    inset: 0;
    justify-content: center;
    padding: 1em;
    position: fixed;
    text-align: center;
}

.error {
    color: #800;
}

.continue {
    font-size: 2rem;
    font-weight: bold;
}
</style>
